<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="cancelPacketsReturn" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("packetsReturn.take-over") }}</h2>
        <h1 v-if="sender">{{ sender }}</h1>
      </template>

      <template v-slot:right-action v-if="!emptyPackets">
        <a
          href="#"
          v-if="showSelectAll"
          @click.prevent="togglePacketsSelection"
          >{{ $t("packetsReturn.select-all") }}</a
        >
        <a href="#" v-else @click.prevent="togglePacketsSelection">{{
          $t("packetsReturn.cancel-all")
        }}</a>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="container">
        <div v-if="!emptyPackets" class="heading">
          <h4>{{ $t("packetsReturn.list") }}</h4>
          <p>{{ $t("packetsReturn.choose") }}</p>
        </div>
        <div class="text-center list" v-else>
          <h1>{{ $t("packetsReturn.no-packets") }}</h1>
        </div>
        <Errors :errors="errors" />
        <label class="item" v-for="packet in packets" :key="packet.barcode">
          <input
            type="checkbox"
            class="checkbox"
            :value="packet.barcode"
            v-model="selectedPackets"
          />
          <div class="flex-group">
            <h3>{{ packet.barcode }}</h3>
            <p>{{ `${$t("packetsReturn.recipient")} ${packet.recipient}` }}</p>
          </div>
          <p class="shelf">{{ packet.shelf }}</p>
        </label>
      </section>
    </main>

    <footer ref="footer">
      <div class="container">
        <a
          href="#"
          :class="[
            'btn btn-primary  btn-white',
            selectedPackets.length < 1 ? 'btn-grey disable' : 'btn-green'
          ]"
          @click.prevent="printSelectedPacketsLabel"
          >{{ $t("packetsReturn.selected-print") }}</a
        >
        <a
          href="#"
          :class="[
            'btn btn-primary',
            selectedPackets.length < 1 ? 'btn-grey disable' : 'btn-green'
          ]"
          @click.prevent="deliverPackets"
          >{{ $t("packetsReturn.selected") }}</a
        >
        <a href="#" class="a-black" @click.prevent="cancelPacketsReturn">{{
          $t("packetReturnShipment.cancel")
        }}</a>
      </div>
    </footer>
    <OverlayAccept
      v-if="showAcceptOverlay"
      :heading="$t('packetsReturn.release-refund')"
    />
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { isEmpty, delay } from "lodash";
import { MOBILE_TYPES } from "@/util/mobile.type";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import OverlayAccept from "@/components/OverlayAccept";
import Close from "vue-material-design-icons/Close.vue";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";

export default {
  name: "PacketsReturn",
  mixins: [setActualFooterHeight, getParams],
  components: { Errors, AppHeader, OverlayAccept, Close, Spinner },
  data() {
    return {
      code: this.$route.params.code,
      selectedPackets: [],
      showSelectAll: true,
      showAcceptOverlay: false
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    this.resetPacketAndPaymentState();
    next();
  },

  async mounted() {
    if (isEmpty(this.packets)) {
      await this.getReturnPackets(this.code);
    }

    this.togglePacketsSelection();
  },

  watch: {
    selectedPackets() {
      this.showSelectAll = this.selectedPackets.length == 0;
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.toggleSelectAllValue(this.showSelectAll);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.toggleSelectAllValue.postMessage({
            showSelectAll: this.showSelectAll
          });
        }
      }
    },
    toggleSelectionClicked() {
      this.togglePacketsSelection();
    }
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("packet", ["packets", "sender", "toggleSelectionClicked"]),

    emptyPackets() {
      return isEmpty(this.packets);
    }
  },

  methods: {
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("packet", [
      "getReturnPackets",
      "deliverReturnPackets",
      "printSelectedPackets"
    ]),
    ...mapMutations(["clearErrors"]),

    cancelPacketsReturn() {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.closeDetail();
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.closeDetail.postMessage({});
        }
      } else {
        if (window.confirm(this.$t("packetsReturn.cancel-return-confirm"))) {
          this.$router.replace({
            name: "packet-search",
            query: {
              platform: this.platform,
              device: this.device
            }
          });
        }
      }
    },

    async printSelectedPacketsLabel({ target }) {
      const response = await this.printSelectedPackets(this.selectedPackets);
      if (!response) return;

      target.innerText = this.$t("packetsReturn.selected-reprint");
    },

    async deliverPackets() {
      const response = await this.deliverReturnPackets({
        password: this.code,
        barcodes: this.selectedPackets
      });
      if (!response) return;

      this.showAcceptOverlay = true;

      delay(() => {
        this.showAcceptOverlay = false;

        this.$router.replace({
          name: "packet-search",
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }, 1500);
    },

    togglePacketsSelection() {
      if (this.showSelectAll) {
        this.packets.forEach(packet => {
          this.selectedPackets.push(packet.barcode);
        });
      } else {
        this.selectedPackets = [];
      }

      this.showSelectAll = !this.showSelectAll;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  p {
    color: $light-grey;
  }

  label {
    font-weight: normal;
  }

  .heading {
    padding: 15px 15px 25px;
  }

  .list {
    margin-top: 30px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid $grey;
    box-sizing: border-box;

    &:hover {
      background: $pink;
      cursor: pointer;
    }

    .checkbox {
      height: 18px;
      width: 18px;
      margin-right: 12px;
      cursor: pointer;
      box-shadow: none;
    }

    .flex-group {
      margin-right: auto;
    }

    .shelf {
      align-self: flex-start;
    }
  }
}

footer {
  a {
    margin-bottom: 10px;

    &:last-child {
      display: inline-block;
      margin: 16px 0 26px;
    }
  }
}

@media (max-width: 380px) {
  h1 {
    font-size: 1.1rem;
  }
}

@media (max-width: 350px) {
  h1 {
    font-size: 0.8rem;
  }
}
</style>
